import React from 'react';
import {Link} from 'react-router-dom';
import styled from "styled-components";
import launchpads from "../config/constants/launchpads";

interface IdoItemProps {
    pid: number;
    coming: boolean;
    isActive: boolean;
    limitSale: boolean;
    stakingCap?: number;
    tokenForSale: number;
    tokenName: string;
    title: string;
    description?: string;
    mmproCap: number;
}

const MainWrapper = styled.div`
  padding: 24px 24px 0px 24px;
  background: rgb(255, 255, 255);
  border-radius: 5px 5px 0 0;
  box-shadow: rgb(0 0 0 / 9%) 0px 4px 50px;
  position: relative;
  height: 100%;
  color: #000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
  }
`;

const IdoImage = styled.img`
  width: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const LiveStatusClosed = styled.p`
  font-weight: 500;
  font-size: 16px;
  background: rgb(226, 225, 225);
  color: rgb(84, 84, 84);
  border-radius: 12px;
  padding: 5px 12px;
  width: 100px;
`;

const LiveStatus = styled.p`
  font-weight: 500;
  font-size: 16px;
  background: rgb(0, 255, 185);
  color: rgb(9, 93, 68);
  border-radius: 12px;
  padding: 5px 12px;
  width: 80px;

  animation: liveAnimate 1.5s infinite;

  @keyframes liveAnimate {
    0% {
      background: rgb(0, 255, 185);
    }
    50% {
      background: rgb(0, 196, 255);
    }
    100% {
      background: rgb(0, 255, 185);
    }
  }
`;
const LiveStatusComing = styled.p`
  font-weight: 500;
  font-size: 16px;
  background: rgb(187, 0, 255);
  color: rgb(245, 245, 245);
  border-radius: 12px;
  padding: 5px 12px;
  width: 120px;

  animation: liveAnimateComing 1.5s infinite;

  @keyframes liveAnimateComing {
    0% {
      background: rgb(247, 0, 255);
    }
    50% {
      background: rgb(0, 196, 255);
    }
    100% {
      background: rgb(247, 0, 255);
    }
  }
`;

const IdoSettings = styled.p`
  margin-top: 12px;
  color: rgba(0, 0, 0, 1);

`;
const IdoItemContent = styled.div`
  width: 100%;
  max-width: calc(33% - 10px);
  margin: 5px;
  cursor: pointer;

  a:hover {
    color: #fff;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 12px;
  }
`;


const IdoItem: React.FC<IdoItemProps> = ({
                                             pid, coming, isActive, limitSale, stakingCap,
                                             description, tokenName, title, tokenForSale, mmproCap
                                         }) => {

    const pageInfo = launchpads.filter((farm) => farm.pid === pid);
    const subStartTime = new Date(pageInfo[0].subStartTime * 1000);
    const localeOptions: any = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};


    return (
        <IdoItemContent>
            <Link to={`project/${pid}`}>
                <MainWrapper>
                    <div style={{width: "100%"}}>
                        {!isActive && !coming && <LiveStatusClosed> ◉ Closed </LiveStatusClosed>}
                        {isActive && !coming && <LiveStatus> ◉ Live </LiveStatus>}
                        {pageInfo[0].isIPO && coming && <LiveStatusComing> ◉ Shares </LiveStatusComing>}
                        {!pageInfo[0].isIPO && coming && <LiveStatusComing> ◉ Coming </LiveStatusComing>}
                    </div>
                    <IdoImage src={`/images/ido/${tokenName}.png`} alt=""/>
                    <h1 style={{fontWeight: 300}}> {title} </h1>
                    <p style={{marginTop: 12, color: "rgba(0,0,0,0.75)", fontSize: 13, height: 86}}>
                        {description}
                    </p>
                    <div style={{
                        display: "none",
                        alignItems: 'center',
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: 30
                    }}>
                        <IdoSettings> Buy Limit Per User </IdoSettings>
                        <IdoSettings> {limitSale ? `${stakingCap} USD` : "-"} </IdoSettings>
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: 8,
                    }}>
                        <IdoSettings> MMPRO Cap Per User </IdoSettings>
                        <IdoSettings> {limitSale ? `${mmproCap}` : "-"} </IdoSettings>
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: 0,
                        marginBottom: 10
                    }}>
                        <IdoSettings>
                            {pageInfo[0].isIPO ? 'Shares For Sale' : 'Token For Sale'}
                        </IdoSettings>
                        <IdoSettings>
                            {tokenForSale > 0 ? tokenForSale.toLocaleString() : 'TBA'} {tokenForSale > 0 ? tokenName : ''}
                        </IdoSettings>
                    </div>

                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: 8,
                        marginBottom: 30
                    }}>
                        <IdoSettings> Subscription starts</IdoSettings>
                        <IdoSettings>
                            {pageInfo[0].tba && !pageInfo[0].showStartTime ? 'TBA' : ''}
                            {pageInfo[0].tba && pageInfo[0].showStartTime ? subStartTime.toLocaleString('en-GB', localeOptions) : ''}
                            {!pageInfo[0].tba && pageInfo[0].showStartTime ? subStartTime.toLocaleString('en-GB') : ''}
                        </IdoSettings>
                    </div>
                </MainWrapper>
                {limitSale ?
                    <div style={{
                        display: "flex", alignItems: 'center', justifyContent: "center",
                        width: "100%", background: "rgb(179,0,255)", padding: 12, borderRadius: "0 0 5px 5px"
                    }}>
                        <p style={{fontSize: 18}}> Limit sale </p>
                    </div>
                    :
                    <div style={{
                        display: "flex", alignItems: 'center', justifyContent: "center",
                        width: "100%", background: "rgb(0,166,255)", padding: 12, borderRadius: "0 0 5px 5px"
                    }}>
                        <p style={{fontSize: 18}}> Unlimited sale </p>
                    </div>
                }
            </Link>
        </IdoItemContent>
    );
};

export default IdoItem;
