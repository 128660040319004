import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'
import { getAddress } from '../../utils/addressHelpers'

// Use it only for OUR pool. Only for except errors. Don`t use this value in counting
const STUB_END_MASTERCHEF_ADDRESS = getAddress(contracts.masterChef)
const STUB_END_MASTERCHEF_PID = 0

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'USDT',
    lpAddresses: {
      97: '0x77eA4310c21B234D8e9d5f2c5974994de33f71e6',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0x77eA4310c21B234D8e9d5f2c5974994de33f71e6',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isOurPool: true,
    endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
    pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
    endMasterChefTokenSymbol: null,
    stratAddress: getAddress(contracts.masterChef),
    doubleFarmActive: true,
    doubleToken: "0xeB1cD7fB73c1DB81AEb5944c4d4989363eF73e14",
    doubleName: "BONUS",
    doublePerBlock: 1
  },
]

export default farms
