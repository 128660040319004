import React from 'react'
import {Provider} from 'react-redux'
import {Web3ReactProvider} from '@web3-react/core'
import {ThemeProvider} from 'styled-components'
import bsc, {UseWalletProvider} from '@binance-chain/bsc-use-wallet'
import store from 'state'
import {RefreshContextProvider} from 'contexts/RefreshContext'
import {getLibrary} from './utils/web3React'
import ThemeStyle from './style/Theme'
import getRpcUrl from './utils/getRpcUrl'
import PricesContextProvider from './contexts/PricesContext'
import ModalProvider from './contexts/ModalContext'

const Providers: React.FC = ({children}) => {
    const rpcUrl = getRpcUrl()

    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Provider store={store}>
                <ThemeProvider theme={ThemeStyle}>
                    <UseWalletProvider
                        chainId={parseInt(process.env.REACT_APP_CHAIN_ID)}
                        connectors={{
                            walletconnect: {rpcUrl},
                            bsc,
                        }}
                    >
                        <RefreshContextProvider>
                            <PricesContextProvider>
                                <ModalProvider>{children}</ModalProvider>
                            </PricesContextProvider>
                        </RefreshContextProvider>
                    </UseWalletProvider>
                </ThemeProvider>
            </Provider>
        </Web3ReactProvider>
    )
}

export default Providers
