import React from 'react'
import styled from 'styled-components'
import logoImg from '../../assets/MMLogoWhite.png'
import {IHeaderProps} from './types'
import WalletButtonBase from './views/WalletButton'
import Spacer from "../Spacer";

const Block = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 84px;
  margin: 0 auto;
  max-width: 1160px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px 0 16px;
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
  }
`

const Logo = styled.div`
  margin-left: 20px;

  @media (max-width: 767.9px) {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
  }
`

const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
  @media (max-width: 767.9px) {
    margin-bottom: 20px;
    margin-right: 0;
  }
`

const Header = (props: IHeaderProps) => {

    return (
        <Block>

            <Logo>
                <a href='https://marketmaking.pro/' target='_blank' rel='noreferrer'>
                    <img src={logoImg} alt='MMPRO' style={{height: 60}}/>
                </a>
            </Logo>

            <FlexEnd>
                <a href="https://launchpad.marketmaking.pro">Launchpad</a>
                <Spacer/>
                <a href="https://farming.marketmaking.pro">Farming</a>
                <Spacer/>
                <a href="/guide.pdf" target="_blank">How it works?</a>
            </FlexEnd>

            <FlexEnd>
                <WalletButtonBase/>
            </FlexEnd>

        </Block>
    )
}

export default Header
