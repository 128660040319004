import React from 'react'
import {useWeb3React} from '@web3-react/core'
import styled from 'styled-components'
import Button from '../../Button'
import {ButtonAppearanceEnum} from '../../Button/types'
import useModal from '../../../hooks/useModal'
import ConnectModal from '../../ConnectModal'
import useAuth from '../../../hooks/useAuth'
import truncate from '../../../utils/truncate'

const Account = styled.div`
  background-color: rgba(229, 229, 229, 0.12);
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px 10px;
  font-size: 13px;
  font-weight: 700;
  border-radius: 5px;
  width: 150px;
  justify-content: center;

  :hover {
    opacity: ${({theme}) => theme.opacity};
    cursor: pointer;
  }
`

// const Cancel = styled.span`
//   padding: 0 !important;
//
//   :hover {
//     opacity: ${({theme}) => theme.opacity};
//     cursor: pointer;
//   }
//
//   svg {
//     width: 8px;
//     height: 8px;
//     margin-right: 5px;
//   }
//
//   path {
//     fill: ${({theme}) => theme.colors.primary} !important;
//   }
// `


const Address = styled.span`
  padding: 0 !important;
`
const WalletButton = () => {
    const {openModal} = useModal()
    const {account} = useWeb3React()
    const {login, logout} = useAuth()

    return account ? (
        <Account onClick={logout}>
            {/* <Cancel onClick={logout}><SVG src={cancelIcon} /></Cancel> */}
            <Address>{truncate(account)}</Address>
        </Account>
    ) : (
        <Button
            onClick={() =>
                openModal({
                    children: ConnectModal,
                    props: {login},
                })
            }
            appearance={ButtonAppearanceEnum.primary}
        >
            Connect Wallet
        </Button>
    )
}

export default WalletButton
