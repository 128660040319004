/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit'
import fetchFarms from './fetchFarms'
import {
    fetchFarmUserAllowances,
    fetchFarmUserInfo,
    fetchPending,
    fetchStablesAllowance,
    fetchStablesBalances,
    fetchTokensAllowance,
} from './fetchFarmUser';
import {Farm, FarmsState} from '../types'
import launchpads from "../../config/constants/launchpads";

const initialState: FarmsState = {data: [...launchpads]}

export const farmsSlice = createSlice({
    name: 'Farms',
    initialState,
    reducers: {
        setFarmsPublicData: (state, action) => {
            const liveFarmsData: Farm[] = action.payload
            state.data = state.data.map((farm) => {
                const liveFarmData = liveFarmsData.find((f) => f.pid === farm.pid)
                return {...farm, ...liveFarmData}
            })
        },
        setFarmUserData: (state, action) => {
            const {arrayOfUserDataObjects} = action.payload
            arrayOfUserDataObjects.forEach((userDataEl) => {
                const {index} = userDataEl
                state.data[index] = {...state.data[index], userData: userDataEl}
            })
        },
        setTvlInfo: (state, action) => {
            const {arrayOfTVLInfo} = action.payload
            arrayOfTVLInfo.forEach((tvlInfo) => {
                const {index} = tvlInfo
                state.data[index] = {...state.data[index], userData: tvlInfo}
            })
        },
    },
})

// Actions
export const {setFarmsPublicData, setFarmUserData, setTvlInfo} = farmsSlice.actions

// Thunks
export const fetchFarmsPublicDataAsync = () => async (dispatch) => {
    const farms = await fetchFarms()
    dispatch(setFarmsPublicData(farms))
}

export const fetchFarmUserDataAsync = (account) => async (dispatch) => {
    const userFarmAllowances = await fetchFarmUserAllowances(account)
    const userStablesBalances = await fetchStablesBalances(account)
    const userStablesAllowance = await fetchStablesAllowance(account)
    const userInfoFarm = await fetchFarmUserInfo(account)
    const userTokenAllowance = await fetchTokensAllowance(account)
    const userPending = await fetchPending(account)

    const arrayOfUserDataObjects = userInfoFarm.map((farmAllowance, index) => {
        return {
            index,
            allowance: userFarmAllowances[index],
            stables: userStablesBalances,
            stablesAllowance: userStablesAllowance,
            userInfo: userInfoFarm[index],
            tokenAllowance: userTokenAllowance,
            pendingToken: userPending[index]
        }
    })
    dispatch(setFarmUserData({arrayOfUserDataObjects}))
}

export const fetchTVLInfo = () => async () => {
    return 123
}

export default farmsSlice.reducer
