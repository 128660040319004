import { useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { kebabCase } from 'lodash'
import { Toast, toastTypes } from '@pancakeswap-libs/uikit'
import { useDispatch, useSelector } from 'react-redux'
import { Team } from 'config/constants/types'
import useRefresh from 'hooks/useRefresh'
import { useWeb3React } from '@web3-react/core'
import {
  clear as clearToast,
  fetchFarmsPublicDataAsync,
  fetchPoolsUserDataAsync,
  push as pushToast,
  remove as removeToast,
} from './actions'
import { AchievementState, Farm, Pool, ProfileState, State, TeamsState } from './types'
import { fetchProfile } from './profile'
import { fetchTeam, fetchTeams } from './teams'
import { fetchAchievements } from './achievements'
import { useTokenBalanceOnContract } from '../hooks/useTokenBalance'
import { getAddress, getBusdAddress, getCakeAddress, getEthAddress, getWbnbAddress } from '../utils/addressHelpers'
import { BIG_ZERO, getBalanceNumber } from '../utils/formatBalance'
import contracts from '../config/constants/contracts'

const BUSD_CONTRACT = '0xe9e7cea3dedca5984780bafc599bd69add087d56'
const BTCB_CONTRACT = '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c'
const BTCB_USDT_CONTRACT = '0xda28eb7aba389c1ea226a420bce04cb565aafb85'
const WBNB_CONTRACT = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
const OUR_LPCONTRACT_ADDRESS = '0x656aa2eaa2f0ef876686c76c36a010e0f4d1e4a3'
const OUR_CONTRACT = getCakeAddress()
const CAKE_LPCONTRACT_ADDRESS = '0x0ed7e52944161450477ee417de9cd3a859b14fd0'
const CAKE_CONTRACT = '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82'

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
  }, [dispatch, slowRefresh])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
  }
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Prices

export const usePriceBnbBusd = (): number => {
  const busdAddress = getBusdAddress()
  const wbnbAddress = getWbnbAddress()
  const WBNBBUSDPOOL = '0x1b96b92314c44b159149f7e0303511fb2fc4774f'
  const wbnbBalance = getBalanceNumber(useTokenBalanceOnContract(wbnbAddress, WBNBBUSDPOOL))
  const busdBalance = getBalanceNumber(useTokenBalanceOnContract(busdAddress, WBNBBUSDPOOL))
  return wbnbBalance && busdBalance ? busdBalance / wbnbBalance : 0
}

export const usePriceBtcBusd = (): number => {
  const usdtAddress = getAddress(contracts.usdt)
  const btcBalance = getBalanceNumber(useTokenBalanceOnContract(BTCB_CONTRACT, BTCB_USDT_CONTRACT))
  const usdtBalance = getBalanceNumber(useTokenBalanceOnContract(usdtAddress, BTCB_USDT_CONTRACT))
  return btcBalance && usdtBalance ? usdtBalance / btcBalance : 0
}

export const usePriceEthBusd = (): number => {
  const busdAddress = getBusdAddress()
  const ethAddress = getEthAddress()
  const ETHBUSDPOOL = '0xd9a0d1f5e02de2403f68bb71a15f8847a854b494'
  const ethBalance = getBalanceNumber(useTokenBalanceOnContract(ethAddress, ETHBUSDPOOL))
  const busdBalance = getBalanceNumber(useTokenBalanceOnContract(busdAddress, ETHBUSDPOOL))
  return ethBalance && busdBalance ? busdBalance / ethBalance : 0
}

export const usePriceOurTokenBusd = (): BigNumber => {

  const tokenAmount = useTokenBalanceOnContract(OUR_CONTRACT, OUR_LPCONTRACT_ADDRESS)
  const busdAmount = useTokenBalanceOnContract(BUSD_CONTRACT, OUR_LPCONTRACT_ADDRESS)

  return  tokenAmount && busdAmount && new BigNumber(busdAmount.div(tokenAmount))
}

export const usePriceCakeBusd = (bnbPrice: number): BigNumber => {
  const contract = CAKE_CONTRACT
  const lpContract = CAKE_LPCONTRACT_ADDRESS

  const tokenAmount = useTokenBalanceOnContract(contract, lpContract)
  const wbnbAmount = useTokenBalanceOnContract(WBNB_CONTRACT, lpContract)

  return new BigNumber(wbnbAmount.multipliedBy(bnbPrice).div(tokenAmount))
}

export const useTokenPrice = (tokenOne: string, contract: string): number => {
  const tokenAmount = useTokenBalanceOnContract(tokenOne, contract)
  const busdAmount = useTokenBalanceOnContract(BUSD_CONTRACT, contract)
  return busdAmount.toNumber() / tokenAmount.toNumber()
}

export const useTokenPriceAuto = (tokenOne: string, contract: string): number => {
  const tokenAmount = useTokenBalanceOnContract(tokenOne, contract)
  const wbnbAmount = useTokenBalanceOnContract(WBNB_CONTRACT, contract)
  return wbnbAmount.toNumber() / tokenAmount.toNumber()
}

// Toasts
export const useToast = () => {
  const dispatch = useDispatch()
  const helpers = useMemo(() => {
    const push = (toast: Toast) => dispatch(pushToast(toast))

    return {
      toastError: (title: string, description?: string) => {
        return push({
          id: kebabCase(title),
          type: toastTypes.DANGER,
          title,
          description,
        })
      },
      toastInfo: (title: string, description?: string) => {
        return push({
          id: kebabCase(title),
          type: toastTypes.INFO,
          title,
          description,
        })
      },
      toastSuccess: (title: string, description?: string) => {
        return push({
          id: kebabCase(title),
          type: toastTypes.SUCCESS,
          title,
          description,
        })
      },
      toastWarning: (title: string, description?: string) => {
        return push({
          id: kebabCase(title),
          type: toastTypes.WARNING,
          title,
          description,
        })
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    }
  }, [dispatch])

  return helpers
}

// Profile

export const useFetchProfile = () => {
  const { account } = useWeb3React()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProfile(account))
  }, [account, dispatch])
}

export const useProfile = () => {
  const { isInitialized, isLoading, data, hasRegistered }: ProfileState = useSelector((state: State) => state.profile)
  return {
    profile: data,
    hasProfile: isInitialized && hasRegistered,
    isInitialized,
    isLoading,
  }
}

// Teams

export const useTeam = (id: number) => {
  const team: Team = useSelector((state: State) => state.teams.data[id])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTeam(id))
  }, [id, dispatch])

  return team
}

export const useTeams = () => {
  const { isInitialized, isLoading, data }: TeamsState = useSelector((state: State) => state.teams)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTeams())
  }, [dispatch])

  return { teams: data, isInitialized, isLoading }
}

// Achievements

export const useFetchAchievements = () => {
  const { account } = useWeb3React()
  const dispatch = useDispatch()

  useEffect(() => {
    if (account) {
      dispatch(fetchAchievements(account))
    }
  }, [account, dispatch])
}

export const useAchievements = () => {
  const achievements: AchievementState['data'] = useSelector((state: State) => state.achievements.data)
  return achievements
}
