
const launchpads = [

  // {
  //   pid: 0,
  //   limitSale: false,
  //   stakingCap: 0,
  //   mmproCap: 0,
  //   title: "Minto",
  //   tokenForSale: 90000,
  //   tokenName: "BTCMT",
  //   description: "Minto is the project on the HECO chain which allows users to get mining rewards in bitcoin by staking a BTCMT token. Our mission is to make Bitcoin mining and savings available for everyone.",
  //   price: 1.72,
  //   subStartTime: 1638720000,
  //   subEndTime: 1639238400,
  //   saleOpen: 1639238460,
  //   saleClose: 1639324800,
  //   claim: 1639324860,
  //   tba: false,
  //   isIPO: false,
  //   showStartTime: true,
  // },
  // {
  //   pid: 1,
  //   limitSale: true,
  //   stakingCap: 0,
  //   mmproCap: 50000,
  //   title: "Minto",
  //   tokenForSale: 60000,
  //   tokenName: "BTCMT",
  //   description: "Minto is the project on the HECO chain which allows users to get mining rewards in bitcoin by staking a BTCMT token. Our mission is to make Bitcoin mining and savings available for everyone.",
  //   price: 1.72,
  //   subStartTime: 1638720000,
  //   subEndTime: 1639238400,
  //   saleOpen: 1639238460,
  //   saleClose: 1639324800,
  //   claim: 1639324860,
  //   tba: false,
  //   isIPO: false,
  //   showStartTime: true,
  // },

  // {
  //   pid: 2,
  //   limitSale: true,
  //   stakingCap: 0,
  //   mmproCap: 50000,
  //   title: "HardCoin",
  //   tba: false,
  //   tokenForSale: 6000000,
  //   tokenName: "HFC",
  //   description: "The Hardcore Fighting Championship is a decentralized platform which entails an intermix of real combat sports and blockchain technology. Hardcore fans may utilize the Hardcoin token to manage the dynamics of the project. ",
  //   price: 0.02,
  //   subStartTime: 1639422000,
  //   subEndTime: 1639738800,
  //   saleOpen: 1639738860,
  //   saleClose: 1639940400,
  //   claim: 1639940460,
  //   isIPO: false,
  //   showStartTime: true,
  // },

  // {
  //   pid: 3,
  //   limitSale: false,
  //   stakingCap: 0,
  //   mmproCap: 0,
  //   title: "HardCoin",
  //   tba: false,
  //   tokenForSale: 9000000,
  //   tokenName: "HFC",
  //   description: "The Hardcore Fighting Championship is a decentralized platform which entails an intermix of real combat sports and blockchain technology. Hardcore fans may utilize the Hardcoin token to manage the dynamics of the project. ",
  //   price: 0.02,
  //   subStartTime: 1639422000,
  //   subEndTime: 1639738800,
  //   saleOpen: 1639738860,
  //   saleClose: 1639940400,
  //   claim: 1639940460,
  //   isIPO: false,
  //   showStartTime: true,
  // },
  // {
  //   pid: 4,
  //   limitSale: true,
  //   stakingCap: 0,
  //   mmproCap: 50000,
  //   title: "Studyum",
  //   tba: false,
  //   tokenForSale: 600000,
  //   tokenName: "STUD",
  //   description: "Studyum is the next-generation learning experience platform, combining artificial intelligence, facial recognition, 3D volumetric avatars, with NFTfi trading, all on the blockchain.",
  //   price: 0.1,
  //   subStartTime: 1639742400,
  //   subEndTime: 1640260800,
  //   saleOpen: 1640260860,
  //   saleClose: 1640347200,
  //   claim: 1640347260,
  //   isIPO: false,
  //   showStartTime: true,
  // },
  // {
  //   pid: 5,
  //   limitSale: false,
  //   stakingCap: 0,
  //   mmproCap: 0,
  //   title: "Studyum",
  //   tba: false,
  //   tokenForSale: 900000,
  //   tokenName: "STUD",
  //   description: "Studyum is the next-generation learning experience platform, combining artificial intelligence, facial recognition, 3D volumetric avatars, with NFTfi trading, all on the blockchain.",
  //   price: 0.1,
  //   subStartTime: 1639742400,
  //   subEndTime: 1640260800,
  //   saleOpen: 1640260860,
  //   saleClose: 1640347200,
  //   claim: 1640347260,
  //   isIPO: false,
  //   showStartTime: true,
  // },



  {
    pid: 6,
    limitSale: false,
    stakingCap: 0,
    mmproCap: 100000,
    title: "Kraken",
    tba: false,
    tokenForSale: 1875,
    tokenName: "KRAKEN",
    description: "Kraken is one of the oldest cryptocurrency exchanges and the largest BTC / EUR exchange.",
    price: 64,
    subStartTime: 1642953600,
    subEndTime: 1644249600,
    saleOpen: 1644249660,
    saleClose: 1644422400,
    claim: 1644422460,
    isIPO: true,
    showStartTime: true,
  },

  {
    pid: 7,
    limitSale: true,
    stakingCap: 0,
    mmproCap: 100000,
    title: "Kraken",
    tba: false,
    tokenForSale: 1562,
    tokenName: "KRAKEN",
    description: "Kraken is one of the oldest cryptocurrency exchanges and the largest BTC / EUR exchange.",
    price: 64,
    subStartTime: 1642953600,
    subEndTime: 1644249600,
    saleOpen: 1644249660,
    saleClose: 1644422400,
    claim: 1644422460,
    isIPO: true,
    showStartTime: true,
  },

  {
    pid: 9,
    limitSale: false,
    stakingCap: 0,
    mmproCap: 100000,
    title: "Bitpay",
    tba: false,
    tokenForSale: 20000,
    tokenName: "BITPAY",
    description: "BitPay, the world's largest world's largest provider of Bitcoin and cryptocurrency payment services",
    price: 8.5,
    subStartTime: 1644595200,
    subEndTime: 1645459200,
    saleOpen: 1645459260,
    saleClose: 1645632000,
    claim: 1645632060,
    isIPO: true,
    showStartTime: true,
  },

  {
    pid: 8,
    limitSale: true,
    stakingCap: 0,
    mmproCap: 10000,
    title: "Bitpay",
    tba: false,
    tokenForSale: 15000,
    tokenName: "BITPAY",
    description: "BitPay, the world's largest world's largest provider of Bitcoin and cryptocurrency payment services",
    price: 8.5,
    subStartTime: 1644595200,
    subEndTime: 1645459200,
    saleOpen: 1645459260,
    saleClose: 1645632000,
    claim: 1645632060,
    isIPO: true,
    showStartTime: true,
  },

  {
    pid: 10,
    limitSale: true,
    stakingCap: 0,
    mmproCap: 10000,
    title: "Metamask",
    tba: false,
    tokenForSale: 5000,
    tokenName: "METAMASK",
    description: "A crypto wallet & gateway to blockchain apps. " +
        "Trusted by over 21 million users worldwide.\n" +
        "\n",
    price: 150,
    subStartTime: 1650643200,
    subEndTime: 1651507200,
    saleOpen: 1651507260,
    saleClose: 1651766400,
    claim: 1651766460,
    isIPO: true,
    showStartTime: true,
  },

  {
    pid: 11,
    limitSale: false,
    stakingCap: 0,
    mmproCap: 10000,
    title: "Metamask",
    tba: false,
    tokenForSale: 5000,
    tokenName: "METAMASK",
    description: "A crypto wallet & gateway to blockchain apps. " +
        "Trusted by over 21 million users worldwide.\n" +
        "\n",
    price: 150,
    subStartTime: 1650643200,
    subEndTime: 1651507200,
    saleOpen: 1651507260,
    saleClose: 1651766400,
    claim: 1651766460,
    isIPO: true,
    showStartTime: true,
  },

  {
    pid: 12,
    limitSale: true,
    stakingCap: 0,
    mmproCap: 10000,
    title: "Zimabank",
    tba: false,
    tokenForSale: 2000000,
    tokenName: "ZIMABANK",
    description: "Convenient and easy way to earn and spend cryptocurrency across the world. Zima Bank provides private clients, businesses, traders, investors, and crypto users with traditional banking services for crypto",
    price: 0.2,
    subStartTime: 1652184000,
    subEndTime: 1653048000,
    saleOpen: 1653048060,
    saleClose: 1653307200,
    claim: 1653307260,
    isIPO: false,
    showStartTime: true,
  },

  {
    pid: 13,
    limitSale: false,
    stakingCap: 0,
    mmproCap: 10000,
    title: "Zimabank",
    tba: false,
    tokenForSale: 3000000,
    tokenName: "ZIMABANK",
    description: "Convenient and easy way to earn and spend cryptocurrency across the world. Zima Bank provides private clients, businesses, traders, investors, and crypto users with traditional banking services for crypto",
    price: 0.2,
    subStartTime: 1652184000,
    subEndTime: 1653048000,
    saleOpen: 1653048060,
    saleClose: 1653307200,
    claim: 1653307260,
    isIPO: false,
    showStartTime: true,
  },

  {
    pid: 14,
    limitSale: true,
    stakingCap: 0,
    mmproCap: 10000,
    title: "Ledger",
    tba: false,
    tokenForSale: 16000,
    tokenName: "Ledger",
    description: "Ledger wallets are hardware cryptocurrency wallets made by Ledger, a company headquartered in Paris, France. Ledger's hardware wallets are multicurrency wallets that are used to store private keys for cryptocurrencies offline.",
    price: 12,
    subStartTime: 1682078400,
    subEndTime: 1682683200,
    saleOpen:   1682683260,
    saleClose: 1682856000,
    claim: 1682856060,
    isIPO: true,
    showStartTime: true,
  },

  {
    pid: 15,
    limitSale: false,
    stakingCap: 0,
    mmproCap: 0,
    title: "Ledger",
    tba: false,
    tokenForSale: 25000,
    tokenName: "Ledger",
    description: "Ledger wallets are hardware cryptocurrency wallets made by Ledger, a company headquartered in Paris, France. Ledger's hardware wallets are multicurrency wallets that are used to store private keys for cryptocurrencies offline.",
    price: 12,
    subStartTime: 1682078400,
    subEndTime: 1682683200,
    saleOpen:   1682683260,
    saleClose: 1682856000,
    claim: 1682856060,
    isIPO: true,
    showStartTime: true,
  },
  // {
  //   pid: 16,
  //   limitSale: true,
  //   stakingCap: 0,
  //   mmproCap: 10000,
  //   title: "ivendPay",
  //   tba: false,
  //   tokenForSale: 10000000,
  //   tokenName: "ivendPay",
  //   description: "Crypto payment system for vending, retail & e-commerce. Easy. Safe. Fast.",
  //   price: 0.018,
  //   subStartTime: 1685534400,
  //   subEndTime: 1685793600,
  //   saleOpen: 1685793660,
  //   saleClose: 1685988000,
  //   claim: 1685988060,
  //   isIPO: false,
  //   showStartTime: true,
  // },



  // {
  //   pid: 10,
  //   limitSale: false,
  //   stakingCap: 0,
  //   mmproCap: 100000,
  //   title: "Bancambios",
  //   tba: true,
  //   tokenForSale: 0,
  //   tokenName: "Bancambios",
  //   description: "Bancambios is a decentralized ecosystem built on Solana, Velas, Near, Everscale. The project team makes DeFi accessible to developing countries and adheres to an eco-friendly approach.",
  //   price: 1.7,
  //   subStartTime: 1698708000,
  //   subEndTime: 1638713400,
  //   saleOpen: 1638713400,
  //   saleClose: 1638715200,
  //   claim: 1698708000,
  //   isIPO: false,
  //   showStartTime: false,
  // },


    /*
          ==== ID 17 && 18 has WRONG START DAY! DO NOT USE ON PRODUCTION, cant be used for tests only ====
                         ---- do not remove this comment ----
     */


  {
    pid: 19,
    limitSale: true,
    stakingCap: 0,
    mmproCap: 10000,
    title: "ivendPay",
    tba: false,
    tokenForSale: 6000000,
    tokenName: "IVPAY",
    description: "Second Private round. Crypto payment system for vending, retail & e-commerce. Easy. Safe. Fast.",
    price: 0.025,
    subStartTime: 1702036800,
    subEndTime: 1702468800,
    saleOpen: 1702468860,
    saleClose: 1702641600,
    claim: 1702641660,
    isIPO: false,
    showStartTime: true,
  },
  {
    pid: 20,
    limitSale: false,
    stakingCap: 0,
    mmproCap: 10000,
    title: "ivendPay",
    tba: false,
    tokenForSale: 6000000,
    tokenName: "IVPAY",
    description: "Second Private round. Crypto payment system for vending, retail & e-commerce. Easy. Safe. Fast.",
    price: 0.025,
    subStartTime: 1702036800,
    subEndTime: 1702468800,
    saleOpen: 1702468860,
    saleClose: 1702641600,
    claim: 1702641660,
    isIPO: false,
    showStartTime: true,
  },
  {
    pid: 21,
    limitSale: true,
    stakingCap: 0,
    mmproCap: 10000,
    title: "Farcana",
    tba: false,
    tokenForSale: 8000000,
    tokenName: "FAR",
    description: "Farcana is a third-person team ability shooter arena game with strong e-sport focus in Sci-Fi setting, posing as a futuristic action reality show on colonized Mars, where players compete in 4x4 PvP combat matches in various game modes.",
    price: 0.012,
    subStartTime: 1703934000,
    subEndTime: 1704002400,
    saleOpen:   1704002460,
    saleClose: 1704020400,
    claim: 1704020460,
    isIPO: false,
    showStartTime: true,
  },






]

export default launchpads
