import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import farmsConfig from 'config/constants/farms'
import {getAddress, getCakeAddress, getMasterChefAddress} from 'utils/addressHelpers'
import contracts from "../../config/constants/contracts";
import launchpads from "../../config/constants/launchpads";


export const fetchFarmUserAllowances = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'allowance',
      params: [account, masterChefAdress],
    }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}



export const fetchFarmUserStakedBalances = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    return {
      address: masterChefAdress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map((staked) => {
    return new BigNumber(staked[0]._hex).toJSON()
  })
  return parsedEarnings
}

export const fetchOurPoolContractBalances = async () => {
  const calls = farmsConfig.map((farm) => {
    return {
      address: getAddress(farm.lpAddresses),
      name: 'balanceOf',
      params: [farm.stratAddress],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance.balance._hex).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmEndMasterChefAddressInLpContractAddress = async () => {
  const calls = farmsConfig.map((farm) => {
    const masterChefAddress = farm.endMasterChefAddress
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [masterChefAddress],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmLPTokenInValue = async () => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    const valueToken = getAddress(farm.quoteTokenAdresses)
    return {
      address: valueToken,
      name: 'balanceOf',
      params: [lpContractAddress],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchTotalLPSupply = async () => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'totalSupply',
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchStablesBalances = async (account: string) => {

  const stablesArray = [
    getAddress(contracts.busd),
    getAddress(contracts.usdc),
    getAddress(contracts.usdt),
    getAddress(contracts.dai),
  ]

  const calls = stablesArray.map((x) => {
    const lpContractAddress = x
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance.balance._hex).toJSON()
  })
  return parsedTokenBalances
}

export const fetchStablesAllowance = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const stablesArray = [
    getAddress(contracts.busd),
    getAddress(contracts.usdc),
    getAddress(contracts.usdt),
    getAddress(contracts.dai),
  ]

  const calls = stablesArray.map((x) => {
    const lpContractAddress = x
    return {
      address: lpContractAddress,
      name: 'allowance',
      params: [account, masterChefAdress],
    }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}




export const fetchFarmUserInfo = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()
  const filteredLaunchs = launchpads.filter((farm) => !farm.tba)

  const calls = filteredLaunchs.map((farm) => {
    return {
      address: masterChefAdress,
      name: 'userInfo',
      params: [account, farm.pid],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map((staked) => {
    return  staked
  })

  return parsedEarnings
}


export const fetchTokensAllowance = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const tokensArray = [
    getCakeAddress()
  ]

  const calls = tokensArray.map((x) => {
    const lpContractAddress = x
    return {
      address: lpContractAddress,
      name: 'allowance',
      params: [account, masterChefAdress],
    }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchAnyTokensBalances = async (account: string) => {
  const tokensArray = [
    getCakeAddress()
  ]

  const calls = tokensArray.map((x) => {
    const lpContractAddress = x
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })
  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchPending = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()
  const filteredLaunchs = launchpads.filter((farm) => !farm.tba)
  const calls = filteredLaunchs.map((farm) => {
    return {
      address: masterChefAdress,
      name: 'pendingAllocation',
      params: [farm.pid, account],
    }
  })
  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}