import React, {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'
import {ILayoutProps} from './types'

const IndexPage = lazy(() => import('../../routes/IndexPage'))
const ItemAuction = lazy(() => import('../../routes/ItemAuction'))
const SubmitEmail = lazy(() => import('../../routes/SubmitEmail'))

const Layout = (props: ILayoutProps) => {
    const {routes} = props

    return (
        <div>
            <Header routes={routes}/>
            <Switch>
                <Route path="/" exact><IndexPage/></Route>
                <Route path="/submitEmail/:projectName?" exact><SubmitEmail/></Route>
                <Route path="/project/:itemNumber?" exact><ItemAuction/></Route>
            </Switch>
            <Footer routes={routes}/>
        </div>
    )
}

export default Layout
