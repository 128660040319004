import React, { useState } from 'react'
import { isChrome } from 'react-device-detect';
import styled from 'styled-components'

import { useTotalSupply } from 'hooks/useTokenBalance'
import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import numeral from 'numeral'
import { getBalanceNumber } from '../../utils/formatBalance'
import usePrices from "../../hooks/usePrices"

import Button from '../../components/Button'


const Title = styled.p`
  color: ${({theme}) => theme.colors.primary};
  font-weight: 600;
  margin: 0;
  padding: 0;
  font-size: 60px;
  margin-bottom: 6px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
`

const MiniTitle = styled.p`
  color: ${({theme}) => theme.colors.primary};
  font-weight: 300;
  margin: 0;
  padding: 0;
  font-size: 20px;
  margin-bottom: 6px;
  text-align: left;
  max-width: 400px;

  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
    width: 100%;
    padding-left: 22px;
    padding-right: 22px;
    max-width: 100%;
    margin-bottom: 20px;
  }
`

const MainPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 30px;
  max-width: 1400px;
  width: 100%;
`

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: 1400px;
  width: 100%;
  margin-bottom: 100px;
  margin-top: 25px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
`

const StatisticsWrapperMini = styled.div`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  width: 350px;
  margin-left: 50px;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: linear;

  @media (max-width: 768px) {
    margin-left: 0;
  }

  h1 {
    font-size: 16px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.65);
    margin-left: 5px;
    margin-right: 5px;
  }

  p {
    font-size: 16px;
    color: #fff;
  }
`

const FlexCenterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`

const FlexForm = styled.div`
  width: 20vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 80vw;
  }
`

const FlexUnsupported = styled.div`
  width: 30vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 80vw;
  }
`

const EmailInput = styled.input`
  width: 80%;
  margin-top: 30px;
  margin-bottom: 30px;
  background: rgba(255,255,255,0.2);
  border: 0;
  outline: none;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;

  ::placeholder {
    color: white;
  }
`

const ButtonContainer = styled.div`
  width: 80%;
`

const FullWidthButton = styled(Button)`
  display: block;
`

const SubmitEmail: React.FC = () => {

    const {ourToken} = usePrices()
    const tokenSupply = getBalanceNumber(useTotalSupply())
    const {connector, account, activate} = useWeb3React();
    const [Loading, SetLoading] = useState(false)
    const [CorrectEmail, SetCorrectEmail] = useState(false)
    const [Email, SetEmail] = useState('')

    console.log(isChrome)

    const signMessage = async ({ message }) => {
      console.log('step #1')
      const provider = await connector.getProvider()
      console.log('step #2')
      const prov = new ethers.providers.Web3Provider(provider)
      console.log('step #3')
      await activate(connector)
      console.log('step #4')
      await prov.send('eth_requestAccounts', [])
      console.log('step #5')
      const signer = prov.getSigner()
      console.log('step #6')
      signer.signMessage(message).then(response => {
        console.log('step #7')
        fetch(`${process.env.REACT_APP_FIREBASE_BASE_URL}/participant`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            signature: response,
            address: account,
            email: Email,
          })
        }).then(resp => resp.text()).then(d => {
          console.log('step #8')
          alert(d);
          SetLoading(false)
        });
      }).catch(() => {
        console.log('deadbeef');
      })
    };

    const handleForm = async (e) => {
      console.log('outer step #1')
      e.preventDefault();
      console.log('outer step #2')
      SetLoading(true);
      console.log('outer step #3')
      const data = new FormData(e.target)
      console.log('outer step #4')
      const mail = data.get("email")
      console.log('outer step #5')
      const getSignMessageRes = await fetch(`${process.env.REACT_APP_FIREBASE_BASE_URL}/signMessageTemplate`).then(resp => resp.json());
      console.log('outer step #6')
      let signMessageTemplate = getSignMessageRes.message.replace(
        '{address}',
        account
      )
      console.log('outer step #7')
      signMessageTemplate = signMessageTemplate.replace(
        '{email}',
        mail
      )
      console.log('outer step #8')
      await signMessage({ message: signMessageTemplate })
      console.log('outer step #9')
    }

    const handleEmail = (e) => {
      SetEmail(e.target.value);
      const re = new RegExp('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{1,})$');
      SetCorrectEmail(re.test(String(Email).toLowerCase()))
    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <MainPageWrapper>
                <TopWrapper>
                    <div>
                        <Title> Launchpad</Title>
                        <MiniTitle> Connect your wallet & Participate in IDO on MMPRO Launchpad. For allocation you need
                            to have MMPRO token. </MiniTitle>
                    </div>
                    <div>
                        <StatisticsWrapperMini>
                            <h1> MMPRO price</h1>
                            <FlexCenterRow>
                                <p> {ourToken.toNumber().toFixed(4)}</p>
                                <h1> USD </h1>
                            </FlexCenterRow>
                        </StatisticsWrapperMini>
                        <StatisticsWrapperMini>
                            <h1> MMPRO marketcap</h1>
                            <FlexCenterRow>
                                <p> {numeral((ourToken.toNumber() * (tokenSupply))).format('0.0a')}</p>
                                <h1> USD </h1>
                            </FlexCenterRow>
                        </StatisticsWrapperMini>
                        <StatisticsWrapperMini>
                            <h1> MMPRO supply</h1>
                            <FlexCenterRow>
                                <p> {numeral(tokenSupply).format('0.00a')}</p>
                                <h1> MMPRO </h1>
                            </FlexCenterRow>
                        </StatisticsWrapperMini>
                    </div>
                </TopWrapper>
                {
                  !isChrome ? <FlexUnsupported>
                    <h1>Your browser is not yet supported, please, use Google Chrome Browser to access the form</h1>
                  </FlexUnsupported> : <form onSubmit={handleForm}>
                    <FlexForm>
                      <h1>Email Registration</h1>
                      <EmailInput
                        type="email"
                        required
                        placeholder="input Email address"
                        value={Email}
                        onChange={handleEmail}
                        name="email"
                      />
                      <ButtonContainer>
                        <FullWidthButton
                          type="submit"
                          className="primary-button"
                          style={ { width: '100%' } }
                          disabled={ !account ? true : (account && Loading) || !CorrectEmail }
                        >
                          { Loading ? 'Registering...' : 'Register Email' }
                        </FullWidthButton>
                      </ButtonContainer>
                    </FlexForm>
                  </form>
                }
            </MainPageWrapper>
        </div>
    )
}

export default SubmitEmail
