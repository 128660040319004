import React, {useMemo, useState} from 'react'
import styled from 'styled-components'

import {useTotalSupply} from 'hooks/useTokenBalance'
import numeral from 'numeral'
import {getBalanceNumber} from '../../utils/formatBalance'
import IdoItem from "../../components/IdoItem";
import launchpads from "../../config/constants/launchpads";
import usePrices from "../../hooks/usePrices";
import Button from '../../components/Button/Button';
import { ButtonAppearanceEnum } from '../../components/Button/types'


const Title = styled.p`
  color: ${({theme}) => theme.colors.primary};
  font-weight: 600;
  margin: 0;
  padding: 0;
  font-size: 60px;
  margin-bottom: 6px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
`

const MiniTitle = styled.p`
  color: ${({theme}) => theme.colors.primary};
  font-weight: 300;
  margin: 0;
  padding: 0;
  font-size: 20px;
  margin-bottom: 6px;
  text-align: left;
  max-width: 400px;

  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
    width: 100%;
    padding-left: 22px;
    padding-right: 22px;
    max-width: 100%;
    margin-bottom: 20px;
  }
`

const MainPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 30px;
  max-width: 1400px;
  width: 100%;
`

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: 1400px;
  width: 100%;
  margin-bottom: 100px;
  margin-top: 25px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
`

const StatisticsWrapperMini = styled.div`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  width: 350px;
  margin-left: 50px;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: linear;

  @media (max-width: 768px) {
    margin-left: 0;
  }

  h1 {
    font-size: 16px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.65);
    margin-left: 5px;
    margin-right: 5px;
  }

  p {
    font-size: 16px;
    color: #fff;
  }
`

const IdoListWrapper = styled.div`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  transition: linear;
  max-width: 1100px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: calc(100% - 32px);
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
  }
`

const FlexCenterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`

const IdoStatusButton = styled.h1<IdoStatusProps>`
  cursor: pointer;
    //border-bottom: ${props => props.isActive ? "5px solid #07aaf5" : "none"};
  font-size: 18px;
  font-weight: 500;
  padding: 3px 16px;
  border-radius: 5px;
  color: ${props => props.isActive ? "rgba(0,0,0, 0.75)" : "rgba(255,255,255,1)"};
  background: ${props => props.isActive ? "rgba(255,255,255,1)" : "none"};
  //margin: 0px 12px;
`

interface IdoStatusProps {
    isActive: boolean;
}

const IndexPage: React.FC = () => {

    // const ourToken  = usePrices().ourToken
    const {ourToken} = usePrices()
    const tokenSupply = getBalanceNumber(useTotalSupply())

    const currentTime = new Date().getTime() / 1000

    const activePools = useMemo(() => launchpads.filter((item) => currentTime <= item.claim && currentTime > item.subStartTime), [currentTime])
    const noActivePools = useMemo(() => launchpads.filter((item) => currentTime > item.claim), [currentTime])
    const comingPools = useMemo(() => launchpads.filter((item) => currentTime < item.subStartTime), [currentTime])

    const [IdoStatus, SetIdoStatus] = useState(activePools.length > 0 ? 0 : 1)

    const IdoStatusList = ["Live", "Upcoming", "Closed"]

    const url = `https://pancakeswap.finance/swap?outputCurrency=0x6067490d05f3cf2fdffc0e353b1f5fd6e5ccdf70`

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <MainPageWrapper>
                <TopWrapper>
                    <div>
                        <Title> Launchpad</Title>
                        <MiniTitle> Connect your wallet & Participate in IDO on MMPRO Launchpad. For allocation you need
                            to have MMPRO token. </MiniTitle>
                    </div>
                    <div>
                        <StatisticsWrapperMini>
                            <h1> MMPRO price</h1>
                            <FlexCenterRow>
                                <p> {ourToken.toNumber().toFixed(4)}</p>
                                <h1> USD </h1>
                            </FlexCenterRow>
                        </StatisticsWrapperMini>
                        <StatisticsWrapperMini>
                            <h1> MMPRO marketcap</h1>
                            <FlexCenterRow>
                                <p> {numeral((ourToken.toNumber() * (tokenSupply))).format('0.0a')}</p>
                                <h1> USD </h1>
                            </FlexCenterRow>
                        </StatisticsWrapperMini>
                        <StatisticsWrapperMini>
                            <h1> MMPRO supply</h1>
                            <FlexCenterRow>
                                <p> {numeral(tokenSupply).format('0.00a')}</p>
                                <h1> MMPRO </h1>
                            </FlexCenterRow>
                        </StatisticsWrapperMini>
                        <StatisticsWrapperMini style={{ textAlign: "center" }}>
                            <Button appearance={ButtonAppearanceEnum.primary} url={url} style={{ margin: "0 auto" }}>
                                Buy MMPro
                            </Button>
                        </StatisticsWrapperMini>
                    </div>
                </TopWrapper>

                <IdoListWrapper style={{background: "none"}}>
                    <FlexCenterRow style={{width: "100%", justifyContent: "start"}}>
                        {IdoStatusList.map((item, id) => (
                            <IdoStatusButton isActive={IdoStatus === id}
                                             onClick={() => SetIdoStatus(id)}> {item} </IdoStatusButton>
                        ))}
                    </FlexCenterRow>
                </IdoListWrapper>

                <IdoListWrapper style={{justifyContent: "start", minHeight: 200, marginBottom: 100}}>
                    {IdoStatus === 0 && activePools.length > 0 ? activePools.map((item) => (
                            <IdoItem
                                pid={item.pid}
                                coming={currentTime < item.subStartTime}
                                limitSale={item.limitSale}
                                isActive={currentTime <= item.claim && currentTime > item.subStartTime}
                                stakingCap={item.stakingCap}
                                title={item.title}
                                tokenForSale={item.tokenForSale}
                                tokenName={item.tokenName}
                                description={item.description}
                                mmproCap={item.mmproCap}
                            />
                        ))
                        : IdoStatus === 0 &&
                        <h1 style={{width: "100%", textAlign: "center"}}> No active {IdoStatusList[0]} pools. </h1>
                    }
                    {IdoStatus === 1 && comingPools.length > 0 ? comingPools.map((item) => (
                            <IdoItem
                                pid={item.pid}
                                coming={currentTime < item.subStartTime}
                                limitSale={item.limitSale}
                                isActive={currentTime <= item.claim && currentTime > item.subStartTime}
                                stakingCap={item.stakingCap}
                                title={item.title}
                                tokenForSale={item.tokenForSale}
                                tokenName={item.tokenName}
                                description={item.description}
                                mmproCap={item.mmproCap}
                            />
                        ))
                        : IdoStatus === 1 &&
                        <h1 style={{width: "100%", textAlign: "center"}}> No active {IdoStatusList[1]} pools. </h1>
                    }
                    {IdoStatus === 2 && noActivePools.length > 0 ? noActivePools.map((item) => (
                            <IdoItem
                                pid={item.pid}
                                coming={currentTime < item.subStartTime}
                                limitSale={item.limitSale}
                                isActive={currentTime <= item.claim && currentTime > item.subStartTime}
                                stakingCap={item.stakingCap}
                                title={item.title}
                                tokenForSale={item.tokenForSale}
                                tokenName={item.tokenName}
                                description={item.description}
                                mmproCap={item.mmproCap}
                            />
                        ))
                        : IdoStatus === 2 &&
                        <h1 style={{width: "100%", textAlign: "center"}}> No active {IdoStatusList[2]} pools. </h1>
                    }
                </IdoListWrapper>

            </MainPageWrapper>
        </div>
    )
}

export default IndexPage
