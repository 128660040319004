import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import cakeABI from 'config/abi/cake.json'
import { getContract, httpProvider } from 'utils/web3'
import {getEarningsBalance, getTokenBalance, getUserCakeInfo, getUserInfo} from 'utils/erc20'
import { getCakeAddress, getMasterChefAddress } from 'utils/addressHelpers'
import { useWeb3React } from '@web3-react/core'
import useRefresh from './useRefresh'
import { useBunnyFactory, useMasterchef } from './useContract'
import useWeb3 from './useWeb3'
import { getBep20Contract } from '../utils/contractHelpers'
import { BIG_ZERO } from '../utils/formatBalance'

const useTokenBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    let cancelled = false
    const fetchBalance = async () => {
      const res = await getTokenBalance(httpProvider, tokenAddress, account)

      if (!cancelled) {
        setBalance(new BigNumber(res))
      }
    }

    if (account) {
      fetchBalance()
    }

    return () => {
      cancelled = true
    }
  }, [account, tokenAddress, fastRefresh])

  return balance
}

export const useTokenBalanceOnContract = (tokenAddress: string, contractAddress: string) => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    let cancelled = false
    const fetchBalance = async () => {
      const res = await getTokenBalance(httpProvider, tokenAddress, contractAddress)
      if (!cancelled) {
        setBalance(new BigNumber(res))
      }
    }

    fetchBalance()

    return () => {
      cancelled = true
    }
  }, [tokenAddress, slowRefresh, contractAddress])

  return balance
}

export const useTotalSupply = () => {
  const { slowRefresh } = useRefresh()
  const [totalSupply, setTotalSupply] = useState<BigNumber>()

  useEffect(() => {
    let cancelled = false

    async function fetchTotalSupply() {
      const cakeContract = getContract(cakeABI, getCakeAddress())
      let supply = await cakeContract.methods.totalSupply().call()
      supply -= 1000000000000000000000000;
      if (!cancelled) {
        setTotalSupply(new BigNumber(supply))
      }
    }

    fetchTotalSupply()

    return () => {
      cancelled = true
    }
  }, [slowRefresh])

  return totalSupply
}

export const useBurnedBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { slowRefresh } = useRefresh()
  const web3 = useWeb3()

  useEffect(() => {
    let cancelled = false
    const fetchBalance = async () => {
      const contract = getBep20Contract(tokenAddress, web3)
      const res = await contract.methods.balanceOf('0x000000000000000000000000000000000000dEaD').call()
      if (!cancelled) {
        setBalance(new BigNumber(res))
      }
    }
    fetchBalance()

    return () => {
      cancelled = true
    }
  }, [web3, tokenAddress, slowRefresh])

  return balance
}

export default useTokenBalance

export const useEarnings = (pid: number) => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()
  const masterChefContract = useMasterchef()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getEarningsBalance(httpProvider, masterChefContract, pid, account)
      setBalance(new BigNumber(res))
    }

    if (account) {
      fetchBalance()
    }
  }, [account, fastRefresh, masterChefContract, pid])

  return balance
}

export const UserCakeInfo = (pid: number) => {
  const [balance, setBalance] = useState([])
  const { slowRefresh } = useRefresh()
  const CakeContract = useBunnyFactory()
  const masterchefAddress = getMasterChefAddress()

  useEffect(() => {
    let cancelled = false
    const fetchBalance = async () => {
      const res = await getUserCakeInfo(httpProvider, CakeContract, pid, masterchefAddress)
      if (!cancelled) {
        setBalance(res)
      }
    }
    fetchBalance()

    return () => {
      cancelled = true
    }
  }, [slowRefresh, CakeContract, pid, masterchefAddress])

  return balance
}

export const useTotalSupplyOnContract = (address: string) => {
  const { slowRefresh } = useRefresh()
  const [totalSupply, setTotalSupply] = useState<BigNumber>()

  useEffect(() => {
    let cancelled = false

    async function fetchTotalSupply() {
      const cakeContract = getContract(cakeABI, address)
      const supply = await cakeContract.methods.totalSupply().call()
      if (!cancelled) {
        setTotalSupply(new BigNumber(supply))
      }
    }

    fetchTotalSupply()

    return () => {
      cancelled = true
    }
  }, [slowRefresh, address])

  return totalSupply
}


export const useUserInfo = (pid: number) => {
  const [balance, setBalance] = useState([])
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()
  const Masterchef = useMasterchef()

  useEffect(() => {
    let cancelled = false
    const fetchBalance = async () => {
      const res = await getUserInfo(Masterchef, account, pid)

      if (!cancelled) {
        setBalance(res)
      }
    }

    if (account) {
      fetchBalance()
    }

    return () => {
      cancelled = true
    }
  }, [account, fastRefresh, Masterchef, pid])

  return balance
}